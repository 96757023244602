export function applyPolyfills() {
  var promises = [];
  if (typeof window !== 'undefined') {
    var win = window;
    if (!win.customElements || win.Element && (!win.Element.prototype.closest || !win.Element.prototype.matches || !win.Element.prototype.remove || !win.Element.prototype.getRootNode)) {
      promises.push(import( /* webpackChunkName: "polyfills-dom" */'./dom.js'));
    }
    var checkIfURLIsSupported = function () {
      try {
        var u = new URL('b', 'http://a');
        u.pathname = 'c%20d';
        return u.href === 'http://a/c%20d' && u.searchParams;
      } catch (e) {
        return false;
      }
    };
    if ('function' !== typeof Object.assign || !Object.entries || !Array.prototype.find || !Array.prototype.includes || !String.prototype.startsWith || !String.prototype.endsWith || win.NodeList && !win.NodeList.prototype.forEach || !win.fetch || !checkIfURLIsSupported() || typeof WeakMap == 'undefined') {
      promises.push(import( /* webpackChunkName: "polyfills-core-js" */'./core-js.js'));
    }
  }
  return Promise.all(promises);
}